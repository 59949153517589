@use '@angular/material' as mat;
@include mat.core();


$app-panel-primary: mat.define-palette(mat.$blue-palette);
$app-panel-accent: mat.define-palette(mat.$light-blue-palette, A200, A100, A400);
$app-panel-warn: mat.define-palette(mat.$red-palette);


$app-panel-theme: mat.define-light-theme((
  color: (
    primary: $app-panel-primary,
    accent: $app-panel-accent,
    warn: $app-panel-warn,
  )
));
@include mat.all-component-themes($app-panel-theme);

html, body {
   height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.primary-bg{
  background-color: mat.get-color-from-palette($app-panel-primary);
}
.p-color{
  color:mat.get-color-from-palette($app-panel-primary);
}
.spacer{
  flex: 1 1 auto;
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.fine-icon{
  width: 24px !important;
  height: 24px !important;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 18px;
  border: 2px solid #F44336;
  text-align: center;
  color:  #fff;
  background-color: #F44336;
  font-size: 16px;
}
.available-icon{
  width: 24px !important;
  height: 24px !important;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 18px;
  border: 2px solid green;
  text-align: center;
  color:  #fff;
  background-color: green;
  font-size: 16px;
}
.occupied-icon{
  width: 24px !important;
  height: 24px !important;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 18px;
  border: 2px solid #FF9800;
  text-align: center;
  color: #fff;
  background-color: #FF9800;
  font-size: 16px;
}
.custom-icon{
  width: 24px !important;
  height: 24px !important;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 18px;
  border: 2px solid #2196F3;
  text-align: center;
  color: #fff;
  background-color: #2196F3;
  font-size: 16px;
}
.park-icon-30{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px !important;
  height: 48px !important;
  border-radius: 36px;
  border: 2px solid green;
  text-align: center;
  color: #fff;
  background-color: green;
  font-size: 16px;
}
.park-icon-60{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px !important;
  height: 48px !important;
  border-radius: 36px;
  border: 2px solid #FF9800;
  text-align: center;
  color: #fff;
  background-color: #FF9800;
  font-size: 16px;
}
.park-icon-90{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px !important;
  height: 48px !important;
  border-radius: 36px;
  border: 2px solid #F44336;
  text-align: center;
  color: #fff;
  background-color: #F44336;
  font-size: 16px;
}
/* cyan:#00BCD4 
   warn:#F44336 
*/