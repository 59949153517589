// This file was optimized from the original Angular Material theme-color file

@use 'sass:map';
@use '@angular/material' as mat;

// Consolidated color palettes
$_palettes: (
  primary: (
    0: #171717, 10: #171717, 20: #171717, 25: #171717, 30: #007eb8, 35: #005590,
    40: #0061a4, 50: #007bcc, 60: #1e95f2, 70: #63b0ff, 80: #9ecaff, 90: #d1e4ff,
    95: #f0f0f0, 98: #f0f0f0, 99: #f0f0f0, 100: #f0f0f0
  ),
  secondary: (
    0: #212121, 10: #212121, 20: #212121, 25: #212121, 30: #004b70, 35: #005881,
    40: #006493, 50: #007eb8, 60: #0099de, 70: #30b5ff, 80: #8dcdff, 90: #f0f0f0,
    95: #f0f0f0, 98: #f0f0f0, 99: #f0f0f0, 100: #f0f0f0
  ),
  tertiary: (
    0: #212121, 10: #212121, 20: #212121, 25: #212121, 30: #6d238a, 35: #7a3097,
    40: #873ea4, 50: #a258bf, 60: #bf72dc, 70: #db8cf8, 80: #ecb1ff, 90: #f9d8ff,
    95: #feebff, 98: #fff7fb, 99: #fffbff, 100: #ffffff
  ),
  neutral: (
    0: #212121, 10: #212121, 20: #212121, 25: #212121, 30: #43474d, 35: #4f5359,
    40: #5b5f65, 50: #73777e, 60: #8d9198, 70: #a8abb3, 80: #c3c6ce, 90: #dfe2ea,
    95: #eef1f9, 98: #f8f9ff, 99: #fdfcff, 100: #ffffff, 4: #0a0e14, 6: #0f1419,
    12: #1c2026, 17: #262a30, 22: #31353b, 24: #353940, 87: #f0f0f0, 92: #f0f0f0,
    94: #f0f0f0, 96: #f0f0f0
  ),
  neutral-variant: (
    0: #171717, 10: #171717, 20: #171717, 25: #171717, 30: #171717, 35: #171717,
    40: #212121, 50: #707883, 60: #89919d, 70: #a4acb8, 80: #bfc7d4, 90: #f0f0f0,
    95: #eaf1ff, 98: #f8f9ff, 99: #fdfcff, 100: #ffffff
  ),
  error: (
    0: #212121, 10: #212121, 20: #690005, 25: #7e0007, 30: #93000a, 35: #a80710,
    40: #ba1a1a, 50: #de3730, 60: #ff5449, 70: #ff897d, 80: #ffb4ab, 90: #ffdad6,
    95: #ffedea, 98: #fff8f7, 99: #fffbff, 100: #ffffff
  )
);

// Create palette maps more efficiently
$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error)
);

$primary-palette: map.merge(map.get($_palettes, primary), $_rest);
$tertiary-palette: map.merge(map.get($_palettes, tertiary), $_rest);


$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $primary-palette,
    tertiary: $tertiary-palette
  ),
  density: (
    scale: 0,
  )
));

$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $primary-palette,
    tertiary: $tertiary-palette
  ),
  density: (
    scale: 0,
  )
));

:root {
 @include mat.all-component-themes($light-theme);
/*  @include mat.sidenav-overrides((
  container-background-color: var(--primary-background),
)); */

}

[data-theme="light"] {
  --theme-type: light;
  --primary-color:#007bcc;
  --card-color:#fafafa;
  --login-bg-color:#007bcc;
  --primary-background:  #f0f0f0;
  --secondary-background:  #f0f0f0;
  --toolbar-color:#007bcc;
  --text-color:  #171717;
  
}

[data-theme="dark"] {
  --theme-type: dark;
  --primary-color:#007eb8;
  --card-color:#212121;
  --login-bg-color:#212121;
  --primary-background:  #171717;
  --secondary-background:  #43474d;
  --toolbar-color:#171717;
  --text-color:  #f0f0f0;
  @include mat.all-component-colors($dark-theme); 
/*   @include mat.sidenav-overrides((
    container-background-color: var(--primary-background),
  )); */

}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.spacer { flex: 1 1 auto; }
.primary-bg { background-color: var(--primary-color); }
.card-bg { background-color: var(--card-color); }
.login-bg { background-color: var(--login-bg-color); }
.toolbar-bg { background-color: var(--toolbar-color); }
.toolbar-tx { color: #f0f0f0; }
.bg-color { background-color: var(--primary-background) }
.text-color{color: var( --text-color)}
.secondary-bg { background-color: var(--secondary-background); }
.primary-color { color: var(--primary-color); }

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: var(--light-text-color);
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card {
  width: calc(25% - 10px); /* 20px kenar boşluğu */
  margin: 5px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}


.card h2 {
  margin-top: 5px;
  margin-bottom: 6px;
  font-size: 21px;
  color:  var(--text-color);
}

.card p {
  margin-top: 0px;
  margin-bottom: 6px;
  font-size: 21px;
  color: var(--light-text-color);
}

.card .update-time {
  margin-top: 0px;
  font-size: 14px;
  color: var(--lighter-text-color);
}

/* Icon styles - shared properties */
.fine-icon, .available-icon, .occupied-icon, .custom-icon {
  width: 24px !important;
  height: 24px !important;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 18px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.fine-icon {
  border: 2px solid #F44336;
  background-color: #F44336;
}
.available-icon {
  border: 2px solid green;
  background-color: green;
}
.occupied-icon {
  border: 2px solid #FF9800;
  background-color: #FF9800;
}
.custom-icon {
  border: 2px solid #2196F3;
  background-color: #2196F3;
}


/* Park icons - shared properties */
.park-icon-30, .park-icon-60, .park-icon-90 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px !important;
  height: 48px !important;
  border-radius: 36px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.park-icon-30 {
  border: 2px solid green;
  background-color: green;
}
.park-icon-60 {
  border: 2px solid #FF9800;
  background-color: #FF9800;
}
.park-icon-90 {
  border: 2px solid #F44336;
  background-color: #F44336;
}

/* Reference colors:
   cyan: #00BCD4 
   warn: #F44336 
*/